import React, { useContext, useEffect, useState, useCallback, useRef } from "react";
import "./GameModeLobby.scss";
import _ from "lodash";

import { GameEngine, GameMode } from "engine/GameEngine";
import { useTransition, animated } from "@react-spring/web";
import { Transition } from "react-transition-group";
import { priceList } from "game-constants";
import { config } from "config";
import { useFirebase, useAuth, useGameplay } from "App";
import { navigate } from "@reach/router";
// import { FaGear } from "react-icons/fa6";
import { useAuthState } from "react-firebase-hooks/auth";

// import { BiRefresh } from "react-icons/bi";
import { WalletSettings } from "./SettingsLobby";
// import { ImCross } from "react-icons/im";
import { Decimal } from "decimal.js";
// import PuffLoader from "react-spinners/PuffLoader";
import { doc, getDoc, getFirestore, updateDoc } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactMarkdown from "react-markdown";
import { gameRules } from "../../game-constants";
import { useTranslation } from "react-i18next";

import { getAnalytics, logEvent } from "firebase/analytics";

import { getUserBalance, getUserWallet, tryJoiningRoom } from "actions/api";

const GameModeLobby = (props) => {
  const { t } = useTranslation();

  const { Firestore, Analytics } = useFirebase();
  const { currentUser, idToken, Auth } = useAuth();
  const { gameData, newGE } = useGameplay();

  const [gameMode, setGameMode] = useState(null);
  const [priceMode, setPriceMode] = useState(null);
  const [userBalanceObj, setUserBalanceObj] = useState({});
  const [walletAddress, setWalletAddress] = useState("");
  // const [lastRoomPath, setLastRoomPath] = useState();
  const [existingRoomId, setExistingRoomId] = useState();

  const [isRefreshingBalance, setIsRefreshingBalance] = useState(false);
  const [user, loading, error] = useAuthState(Auth);
  const [insufficientBalanceOpen, setInsufficientBalanceOpen] = useState(false);
  const [gameRulesOpen, setGameRulesOpen] = useState(false);
  const [insufficientPrompType, setInsufficientPrompType] = useState("minEntry"); // "newUser" / "minEntry"
  const [minEntryText, setMinEntryText] = useState(0);

  const [openLoadingScreen, setOpenLoadingScreen] = useState(false);
  const [hasExistingRoomToRejoin, setHasExistingRoomToRejoin] = useState(false);
  const [isTryingToJoinRoom, setIsTryingToJoinRoom] = useState(false);

  const nodeRef = useRef(null);

  // // useEffect for language change
  // useEffect(() => {
  //   i18n.changeLanguage("tc");
  // }, [i18n]);

  // Main useEffect to run once user is ready.
  useEffect(() => {
    if (currentUser && idToken) {
      // console.log("[GameLobby useEffect] currentUser", currentUser, idToken);
      getBalance().catch(console.error);
      getWalletAddress().catch(console.error);
      getUserLastRoomId().catch(console.error);
    }
    return () => {};
  }, [currentUser, idToken]);

  useEffect(() => {
    // console.log("props.location.state useEffect trigger...", props.location.state);
    if (props.location.state?.newUser) {
      // console.log("new user navigate from profile setup");
      setTimeout(() => {
        setInsufficientPrompType("newUser");
        setInsufficientBalanceOpen(true);
      }, 1000);
    } else if (props.location.state?.minEntry) {
      setInsufficientBalanceOpen(true);
      setInsufficientPrompType("minEntry");
      setMinEntryText(props.location.state?.minEntryAmount);
    } else if (props.location.state?.fromPath === "settings") {
      // console.log("from settings back");
      setOpenLoadingScreen(false);
      //  nth to change
    } else {
      // console.log("in else locations back to lobby");

      setOpenLoadingScreen(true);

      setTimeout(() => {
        setOpenLoadingScreen(false);
      }, 4000);
    }

    if (currentUser) {
      console.log("sending firebase analytics...");
      logEvent(Analytics, "user_in_lobby", {
        uid: currentUser.uid,
      });
    }
  }, [props.location.state]);

  const getBalance = async () => {
    // let latestIdToken = await Auth.currentUser.getIdToken();
    // let latestIdToken = idToken;
    const latestIdToken = localStorage.getItem("mjToken");
    // console.log("idToken in getBalance()", latestIdToken);
    const userBalanceObj = await getUserBalance(latestIdToken);

    const { usdTokenBalance, pendingUsdWithdraw, availableUsdBalance } = userBalanceObj;

    setUserBalanceObj(userBalanceObj);
    setIsRefreshingBalance(false);
  };

  // const getBalance = useCallback(async () => {
  //   // let latestIdToken = await Auth.currentUser.getIdToken();
  //   let latestIdToken = idToken;
  //   console.log("idToken in getBalance()", latestIdToken);
  //   const userBalanceObj = await getUserBalance(latestIdToken);

  //   const { usdTokenBalance, pendingUsdWithdraw, availableUsdBalance } = userBalanceObj;

  //   setUserBalanceObj(userBalanceObj);
  //   setIsRefreshingBalance(false);
  // }, [currentUser]);

  const getWalletAddress = async () => {
    // let latestIdToken = await Auth.currentUser.getIdToken();
    // let latestIdToken = idToken;
    const latestIdToken = localStorage.getItem("mjToken");
    const wallet = await getUserWallet(latestIdToken);
    // console.log("user wallet:", wallet);
    setWalletAddress(wallet.smartAccountAddress);
  };

  const getUserLastRoomId = useCallback(async () => {
    if (currentUser) {
      const docSnap = await getDoc(doc(Firestore, "users", currentUser.uid));
      let userInfo = docSnap?.data();

      // console.log("[GameLobby] lastRoomPath", userInfo.lastRoomPath);

      if (userInfo.lastRoomPath && typeof userInfo.lastRoomPath !== "string") {
        // console.log("there is a valid lastRoomPath");

        let { gameMode, priceMode, roomId } = userInfo?.lastRoomPath;
        let lastRoomInfo = (await getDoc(doc(Firestore, `${gameMode}-${priceMode}`, roomId))).data();

        // console.log("[GameLobby] user lastRoomInfo", lastRoomInfo);

        // TODO add timestamp checking
        if (lastRoomInfo?.gameStarted === true && lastRoomInfo?.gameEnded !== true) {
          // console.log("user has an going room not yet finished", lastRoomInfo);
          //  game is still ongoing, rejoin

          // let splittedPath = lastRoomPath.split("/");
          setExistingRoomId(roomId);
          setGameMode(gameMode);
          setPriceMode(priceMode);

          setHasExistingRoomToRejoin(true);
        } else {
          // console.log("user does not have an ongoing room");
          setHasExistingRoomToRejoin(false);
        }
      }
    }
  }, [currentUser]);

  function balanceCheckPass(priceMode) {
    let priceModeItem = _.find(priceList, { priceModeId: priceMode });
    // let basePrice = priceModeItem.basePrice;
    let specialPrice = new Decimal(priceModeItem.specialPrice);

    // console.log("priceMode", priceMode);
    // console.log("specialPrice", specialPrice.toFixed(2));

    let minEntryAmount = specialPrice.times(config.MIN_BALANCE_THRESHOLD_MULTIPLE);

    // console.log("minEntryAmount", minEntryAmount.toFixed(2));
    // console.log("userBalance", userBalanceObj.availableUsdBalance);

    if (new Decimal(userBalanceObj.availableUsdBalance).gte(minEntryAmount)) {
      return true;
    } else {
      setInsufficientPrompType("minEntry");
      setMinEntryText(minEntryAmount);
      return false;
    }
  }

  async function handleStart() {
    if (balanceCheckPass(priceMode) && !hasExistingRoomToRejoin) {
      setIsTryingToJoinRoom(true);
      // console.log("Balance check passed, attempting to join a room...");

      // let latestIdToken = await Auth.currentUser.getIdToken(true);
      // let latestIdToken = idToken;
      const latestIdToken = localStorage.getItem("mjToken");
      let roomId;

      roomId = await tryJoiningRoom(gameMode, priceMode, latestIdToken);

      // console.log("[GameLobby]: new roomId from Firestore", roomId);

      setIsTryingToJoinRoom(false);

      if (roomId) {
        // console.log(`Successfully joined the room ${roomId}, navigating to the GamePage`);
        navigate(`/${gameMode}/${priceMode}/${roomId}`);
      } else {
        console.log("no roomId found, redirecting back to lobby");
        // Handle the failure case, maybe show a message to the user
      }
    } else {
      setInsufficientBalanceOpen(true);
      console.log("insufficient balance");
    }
  }

  const defaultStyle = {
    transition: `opacity ${250}ms ease-in`,
    opacity: 0,
  };

  const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  };

  return (
    <>
      <div className="gamemodeLobby-container">
        <div className="lobby-overlay">
          <div className="gamemode-page">
            {/* <button onClick={() => methodDoesNotExist()}>Break the world</button>;<div className="gameTitle"></div> */}
            {gameMode === null ? (
              <>
                <GameModeContainer setGameMode={setGameMode} />
                <div
                  className="lobby-settings-container"
                  onClick={() => {
                    navigate("/Settings", {
                      state: {
                        userBalanceObj: userBalanceObj,
                      },
                    });
                  }}
                >
                  {/* <FaGear className="settings-icon" size={32} /> */}
                  <FontAwesomeIcon icon="fa-gear" size="2x" />
                </div>
              </>
            ) : (
              <>
                <PirceModeContainer gameMode={gameMode} setPriceMode={setPriceMode} t={t} />

                {priceMode !== null ? (
                  <div className="start-game-button" onClick={() => handleStart()}>
                    {t("game_mode_lobby.game_start")}
                  </div>
                ) : null}

                <div
                  className="backTitle"
                  onClick={() => {
                    setGameMode(null);
                  }}
                ></div>
              </>
            )}
            <div className="balance-container">
              <div className="balance-label">
                {t("game_mode_lobby.wallet_balance")}

                <FontAwesomeIcon
                  icon="fa-rotate"
                  style={{ fontSize: 24 }}
                  className={`refresh-icon ${isRefreshingBalance ? "refreshing" : ""}`}
                  onClick={() => {
                    setIsRefreshingBalance(true);
                    getBalance().catch(console.error);
                  }}
                />
              </div>
              <div className="balance-frame">
                <div className="balance-value">
                  {userBalanceObj?.availableUsdBalance ? (
                    <>
                      {new Intl.NumberFormat("en-US", {}).format(new Decimal(userBalanceObj?.availableUsdBalance).toFixed(3))}
                      {" U"}
                    </>
                  ) : (
                    "0"
                  )}
                </div>
              </div>
            </div>
            <div
              className="gameRulesButton"
              onClick={() => {
                setGameRulesOpen(true);
              }}
            >
              <FontAwesomeIcon icon="fa-circle-info" style={{ marginRight: 4 }} />
              {/* <PiSignOutBold size={20} /> */}
              <div
                className="signout-text"
                onClick={async () => {
                  console.log("signout button clicked");
                }}
              >
                {t("game_mode_lobby.game_rules")}
              </div>
            </div>

            <div>
              <a
                className="tgSupportBtn"
                // href="https://t.me/metaverse_casino_support"
                href="https://t.me/metaversemj"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon="fa-brands fa-telegram" style={{ marginRight: 4 }} />
                {/* 客服专线 */}
                {t("settings_lobby.customer_service")}
              </a>
            </div>
          </div>
        </div>

        {insufficientBalanceOpen && (
          <div className="insufficientBalancePrompt">
            {/* <ImCross size={24} className="closePromptIcon" onClick={() => setInsufficientBalanceOpen(false)} /> */}
            <FontAwesomeIcon size="2x" icon="fa-xmark" className="closePromptIcon" onClick={() => setInsufficientBalanceOpen(false)} />

            {insufficientPrompType === "newUser" ? (
              <div className="insufficientText">{t("game_mode_lobby.why_empty_lets_deposit")}</div>
            ) : (
              <div className="insufficientText">
                {t("game_mode_lobby.sorry_wallet_requires_minimum")}{" "}
                <span>{new Intl.NumberFormat("en-US").format(minEntryText.toFixed(0))} USDT</span>{" "}
                {t("game_mode_lobby.sorry_wallet_game_continue")}
              </div>
            )}

            <WalletSettings wallet={walletAddress} t={t} />
          </div>
        )}

        {gameRulesOpen && (
          <div className="gameRulesPrompt">
            {/* <ImCross size={24} className="closePromptIcon" onClick={() => setInsufficientBalanceOpen(false)} /> */}
            <FontAwesomeIcon size="2x" icon="fa-xmark" className="closePromptIcon" onClick={() => setGameRulesOpen(false)} />
            <div className="gameRulesContainer">
              <ReactMarkdown>{gameRules}</ReactMarkdown>
            </div>
          </div>
        )}

        {isTryingToJoinRoom && (
          <div className="matchingOtherPlayersPrompt">
            <div className="title"> {t("game_mode_lobby.connecting_other_players")}</div>
          </div>
        )}

        {hasExistingRoomToRejoin && (
          <div className="rejoinExistingRoomPrompt">
            <div className="title"> {t("game_mode_lobby.previous_game_still_onging")}</div>
            <div
              className="confirm-btn border-button"
              onClick={async () => {
                console.log("room full url:", `/${gameMode}/${priceMode}/${existingRoomId}`);
                // Player reconnection
                navigate(`/${gameMode}/${priceMode}/${existingRoomId}?rejoin=true`);
              }}
            >
              {t("game_mode_lobby.back_to_game")}
            </div>
          </div>
        )}

        <Transition nodeRef={nodeRef} in={openLoadingScreen} timeout={250} unmountOnExit={true}>
          {/* <LoadingScreenContainer /> */}
          {(state) => (
            <div
              className="metaverseLoadingScreen"
              ref={nodeRef}
              style={{
                ...defaultStyle,
                ...transitionStyles[state],
              }}
            >
              <div className="loading-container">
                {/* <PuffLoader color={"#ffffff"} loading={true} size={180} /> */}
                <div className="title">{t("game_mode_lobby.slogan_1")}</div>
                <div className="subtitle">{t("game_mode_lobby.slogan_2")}</div>
                <div className="typewriter">now loading...</div>
              </div>
            </div>
          )}
        </Transition>
      </div>
    </>
  );
};

// function LoadingScreenContainer() {
//   return (

//   );
// }

function GameModeContainer({ setGameMode }) {
  const transitions = useTransition(null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 300 },
  });

  return transitions((style, item) => (
    <animated.div style={style} className={`gamemode-container`}>
      {/* <div className="gamemode-container"> */}
      <div
        className="gamemode-button-container"
        onClick={() => {
          // setTimeout(function () {
          setGameMode(GameMode.basic);
          // }, 300);
        }}
      >
        <div className="base-gamemode-button"></div>
      </div>
      <div
        className="gamemode-button-container"
        onClick={() => {
          // setTimeout(function () {
          setGameMode(GameMode.selfDrawn);
          // }, 300);
        }}
      >
        <div className="self-drawn-gamemode-button"></div>
      </div>
      {/* </div> */}
    </animated.div>
  ));
}

function PirceModeContainer({ setPriceMode, gameMode, t }) {
  const transitions = useTransition(null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 300 },
  });

  // add free mode

  let freeVenue = {
    priceModeId: "0-0",
    basePrice: 0.0,
    specialPrice: 0.0,
  };

  let pagedPriceList = [
    {
      page: 1,
      content: priceList.slice(3, 4),
      levelText: t("game_mode_lobby.gamemode_beginner"),
    },
    {
      page: 2,
      content: priceList.slice(5, 7),
      levelText: t("game_mode_lobby.gamemode_medium"),
    },
    {
      page: 3,
      content: priceList.slice(7, 9),
      levelText: t("game_mode_lobby.gamemode_pro"),
    },
  ];

  // console.log("pagedPriceList", pagedPriceList);

  const [pricePageIndex, setPricePageIndex] = useState(0);
  const [optionSelected, setOptionSelected] = useState(null);

  function getPrevious() {
    setPricePageIndex((pagedPriceList.length + pricePageIndex - 1) % pagedPriceList.length);
    setPriceMode(null);
    setOptionSelected(null);
  }

  function getNext() {
    setPricePageIndex((pagedPriceList.length + pricePageIndex + 1) % pagedPriceList.length);
    setPriceMode(null);
    setOptionSelected(null);
  }

  return transitions((style, item) => {
    return (
      <animated.div style={style} className={`pricemode-container gamemode-${gameMode}`}>
        <div className="buttonLeft" onClick={() => getPrevious()}></div>
        <div className="buttonRight" onClick={() => getNext()}></div>

        <div className="price-selections-container">
          <div className="levelLabel">{pagedPriceList[pricePageIndex].levelText}</div>
          {pagedPriceList[pricePageIndex].content.map((option, index) => {
            return (
              <div
                className={`price-option ${optionSelected === index ? "option-selected" : ""}`}
                key={`${index}`}
                onClick={() => {
                  setPriceMode(option.priceModeId);
                  setOptionSelected(index);
                }}
              >
                {option.basePrice} / {option.specialPrice}
              </div>
            );
          })}
        </div>
      </animated.div>
    );
  });
}

export default GameModeLobby;
